import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

 import { dateFormat }       from "@/directives";

 
import { badgesService } from "@services/badge.service";
import { Badge } from "@/model/Badge";
import moment from "moment";
 

 


@Options({
  name: "BadgeEdit",
  components: {
  },
   directives: { dateFormat }
})
export default class BadgeEdit extends Vue {
  @Prop({ required: true })
  readonly badge!: Badge;

  
 

  onCancel() {
    this.closeDialog();
  }

  onConfirm() {
    this.editBadge();
  }

  getFormatMinutesHour(){
    const minutes = this.badge.total_minutes_worked;
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    // Formattare i minuti con uno zero davanti se sono inferiori a 10
    return `Totale ore ${hours}:${remainingMinutes.toString().padStart(2, '0')}`;
 
  }

  showWaring(){
   
      const formatter = Intl.DateTimeFormat(undefined, 
        {
          month: '2-digit',
          day: '2-digit',
          year: "numeric"
        });
 
    const badge_date = moment(this.badge.badge_date)
    const today = moment()
    return formatter.format(today.toDate()) == formatter.format(badge_date.toDate());
  }

  isLoading: boolean = false; 
  private async editBadge(){
    try {
      this.isLoading = true; 
      await badgesService.updatePatch({id : this.badge.id, note_edit: this.badge.note_edit, total_minutes_worked : this.badge.total_minutes_worked})
      
      this.$successMessage(
        "Dato aggiornato"
      );

      this.closeDialog();

      this.$emit('confirm');
    } catch (error) {
      this.$errorMessage("Errore durante l'aggiornamento");
    } finally {
      this.isLoading = false; 
    }
  }
  
  private closeDialog() {
    this.$emit("update:visible", false);
  }
}