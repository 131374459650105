import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_BadgesTable = _resolveComponent("BadgesTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PageHeader, {
      title: _ctx.$t('menu.badges'),
      showDelete: false,
      showSave: false
    }, null, 8, ["title"]),
    _createVNode(_component_BadgesTable)
  ]))
}