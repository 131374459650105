import { Options, Vue }       from "vue-class-component";
import { Badge, EntriesExit } from "@/model/Badge";
import { badgesService }       from "@services/badge.service";
import { PrimeVueEvent }      from "@/model/common/PrimeVueTypes";
import { FilterMatchMode }    from "primevue/api";
import { BadgesRoutesEnum }    from "../../../router";
import { SessionStorageEnum } from "@/utils/SessionStorageEnum";
import { authStore }          from "@/modules/auth/store";
import { dateFormat }       from "@/directives";
import moment                                                       from "moment";
import { TableSkeleton } from '@sharedComponents';
import { Taker } from "@/model/Taker";
import { plainToClass } from "class-transformer";
import { fileDownload }     from "@sharedUtils/utils";
import {
  AlertDialog,  PageHeader, ProgressDialog, TakerAutocomplete,
} from "@/components"
import { TakersRoutesEnum } from "@/modules/takers/router";
import BadgeEdit from "../../BadgeEdit/BadgeEdit.vue";

@Options( {
  components: {
    TableSkeleton,
     TakerAutocomplete,
     BadgeEdit
  },
  directives: { dateFormat }
} )
export default class BadgesTable extends Vue {
  advFilters: BadgeAdvFilters = new BadgeAdvFilters();
  firstLoading: boolean = true;
  loading = false;

  badges: Badge[] = null;

  totalRecords = 0;

  filters: any = null;

  get dt(): any {
    return this.$refs.dt;
  }

  get stateKey() {
    return SessionStorageEnum.TABLE_STATE_BADGES;
  }


  private async loadData(params?: PrimeVueEvent) {
    params?.originalEvent && delete params.originalEvent;
 
    try {
      this.loading = true;

      const {
              filters,
              sortField,
              sortOrder
            } = params || {};

      const p = {
        sortField,
        sortOrder,
        per_page: params?.rows,
        page: (params?.first ? params.first / params.rows : 0) + 1,
      } as any;

      if (filters && Object.keys(filters).length) {
        p.filters = { ...filters };
      }
      const response = await badgesService.index(p);

      const { data, total } = response;

      this.badges = data;
      this.totalRecords = total;

    } catch (error) {
      console.error(error);
      this.$errorMessage("", "Caricamento interroto");
    } finally {
      this.loading = false;
      this.firstLoading = false;
    }
  }

  private initFilter() {
    this.filters = {
    entity_id: {
        value    : null,
        matchMode: FilterMatchMode.EQUALS
      },
    badge_date: {
        value: [this.advFilters.from, this.advFilters.to],
        matchMode: FilterMatchMode.BETWEEN
      },
    };
    this.advFilters = new BadgeAdvFilters();
  }

  private getFilters() {
    return {
      ...this.dt.createLazyLoadEvent(),
      filters: this.filters
    }
  }

  onApplyFilter() {
    this.loadData(this.getFilters());
  }

  updateFilters() {
     
      // Period
      if (this.advFilters.from && this.advFilters.to) {
          this.filters.badge_date.value = [
          moment( this.advFilters.from ).format( 'yyyy-MM-DD' ),
          moment( this.advFilters.to ).format( 'yyyy-MM-DD' )
        ];
        this.filters.badge_date.matchMode = FilterMatchMode.BETWEEN;

      } else if (this.advFilters.from) {
         this.filters.badge_date.value      = this.advFilters.from;
         this.filters.badge_date.matchMode = FilterMatchMode.GREATER_THAN_OR_EQUAL_TO;
      }
 
      this.filters.entity_id.value =  this.advFilters.taker?.id;
       this.filters.entity_id.matchMode = FilterMatchMode.EQUALS;

    // Refresh
    this.onApplyFilter();

   
  }


  onClearFilter() {
    this.advFilters = new BadgeAdvFilters();
    this.initFilter();
    this.updateFilters();
  }

  onPage(e: Event | PrimeVueEvent): any {
    const pe = e as PrimeVueEvent;
    this.onApplyFilter()
  }

  onSort(e: Event): any {
    const pe = e as PrimeVueEvent;
   this.onApplyFilter()
  }

  onRowClick(badge: Badge) {
     
  }

  onDelete(badge: Badge) {
    const message = "Vuoi eliminare completamente questo dato?";

    this.$confirmMessage(message)
      .then(async response => {
        if (response) {
          this.$waitFor(
            async () => {
              await badgesService.remove(badge);

              this.onApplyFilter()

              this.$successMessage("Elemento cancellato correttamente");
            },
            "Cancellazione non riuscita"
          )
        }
      })

  }

  onEdit(item: Badge) {
    this.showBadgeEditDialog.badge = {...item};
     this.showBadgeEditDialog.show = true;
  }
 
  created() {
    this.initFilter();
  }

  mounted() {
    this.updateFilters()
  }

  errorCaptured(e: Error, v: Vue<BadgesTable>, i: string) {
    return true;
  }

  bykerLink(item: Badge){
    return { name: TakersRoutesEnum.TAKERS_DETAIL, params: { takerId: item.entity_id } }
  }

  async onExportClick(){
    try {
          this.loading = true;
        const filters = this.getFilters().filters;
        const response = await badgesService.exportExcel( { ...filters });
        const startPeriod = (filters.badge_date.value[0]);
        const endPeriod = (filters.badge_date.value[1]);

        const fileName = [
          "timbrature",
          startPeriod,
          endPeriod,
        ].filter(x => x).join('_');

        fileDownload(
          `${fileName}.xlsx`,
          response,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );


         

        } catch (error) {
          console.error(error);
          this.$errorMessage("", "Caricamento interroto");
        } finally {
          this.loading = false;
          this.firstLoading = false;
        }
  }
  showBadgeEditDialog = new BadgeEditData();
  confirmBadegeEditCallback(){
    this.onApplyFilter();
  }
  formatter = Intl.DateTimeFormat(undefined, 
        {
          month: '2-digit',
          day: '2-digit',
          year: "numeric"
        });
 badgeDate(badge: Badge){
    return this.formatter.format(moment(badge.badge_date).toDate())
  }
}

export type FromTo = [Date,Date] | Date[];
export class BadgeEditData {
  show: Boolean = false;
  badge?: Badge =  null
}
export class BadgeAdvFilters {
    from_to?: FromTo = null;
    get from() {
      const from = this.from_to?.[0];
      if(from) {
        from.setHours(0);
        from.setMinutes(0);
        from.setSeconds(0);
        return from;
      }

      return null; 
    }
    
    get to() {
      const to = this.from_to?.[1] ;
      if(to) {
        to.setHours(23);
        to.setMinutes(59);
        to.setSeconds(59);
        return to;
      }

      return null; 
    }
    private _taker   : Taker    = null;
    get taker(){
      return this._taker;
    }
    set taker(value: Taker) {
      this._taker         = value;
    }


    constructor() {
      const from = moment();
      const to = moment();

      this.from_to = [ from.toDate(), to.toDate() ];
    }
}
