import { Options, Vue }                    from "vue-class-component";
import { SessionStorageEnum }              from "@/utils/SessionStorageEnum";

import BadgesTable from './BadgesTable/BadgesTable.vue';

import { badgesService } from "@services/badge.service";

import {
  PageHeader,
} from "@/components";

@Options({
  components: {
    BadgesTable,
    PageHeader,
  },
   
  beforeRouteEnter(to, from, next) {
    //sessionStorage.removeItem(SessionStorageEnum.TABLE_STATE_BADGES);

    next();
  },
  beforeRouteLeave() {
    if (badgesService?.cancelPendingRequests) {
      badgesService.cancelPendingRequests();
    }
  }
})
export default class BadgesPage extends Vue {
   
}
