import { Badge } from '@/model/Badge';
import { CRUD } from './base/crud';

class BadgesService extends CRUD<Badge, any> {
    readonly endPoint = `admin/badges`;


    public exportExcel(
        filters
    ): Promise<string>{

        if (filters) {
            Object.keys(filters).forEach(k => {
                const v = filters[k]?.value; 
                const n = filters[k]?.nullable; 
    
                if (!v && !n) {
                    delete filters[k];
                }
            });

            if (!Object.keys(filters)?.length) {
                filters = null; 
            }
        }

        return this.get<string>(`${this.endPoint}/export`,  {
            responseType: 'blob',
            params: {
                filters
            }
        })
    } 
}

export const badgesService = new BadgesService();




